import React, { Fragment, useEffect, useRef } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import { faceZoomInUp, fadeInUp, zoomIn } from '../animation/FadeInUp';
import { Col, Image, Row } from 'react-bootstrap';

const RoadmapSec = () => {
    const titleRef = useRef(null);
    const roadmaptext1 = useRef(null);
    const roadmaptext2 = useRef(null);
    const roadmaptext3 = useRef(null);
    const roadmaptext4 = useRef(null);

    useEffect(() => {
        const split = new SplitType('.text-ref', { types: 'chars,words' });

        const applyTextAnimation = (textRef) => {
            const chars = textRef.querySelectorAll('.char');

            const tl = gsap.timeline({ delay: 0.8 });

            tl.fromTo(
                chars,
                { opacity: 0, y: 20, skewY: 10 },
                {
                    opacity: 1,
                    y: 0,
                    ease: 'power4.out',
                    skewY: 0,
                    stagger: { amount: 0.4 },
                    duration: 1,
                }
            );

            // Remove observer after animation is triggered once
            observer.disconnect();
        };

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target === titleRef.current) {
                        fadeInUp(titleRef.current, { duration: 0.5, delay: 0.3 });
                        applyTextAnimation(roadmaptext1.current);
                        applyTextAnimation(roadmaptext2.current);
                        applyTextAnimation(roadmaptext3.current);
                        applyTextAnimation(roadmaptext4.current);
                        fadeInUp(".minititle-1", { duration: 0.5, delay: 0.5 });
                        fadeInUp(".minititle-2", { duration: 0.5, delay: 0.5 });
                        fadeInUp(".minititle-3", { duration: 0.5, delay: 0.5 });
                        fadeInUp(".minititle-4", { duration: 0.5, delay: 0.5 });
                    }

                }
            });
        }, options);

        if (titleRef.current) {
            observer.observe(titleRef.current);
        }

        return () => {
            if (titleRef.current) {
                observer.unobserve(titleRef.current);
            }
        };
    }, []);

    return (
        <>
            <Image src={'/img/roadmap-shadow.png'} className='roadmap-shadow' width={'300'} height={'300'} />
            <div className='roadmap-area'>
                <div className='container position-relative'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='section-title-area small-shadow-space shadow-centered text-start position-relative'>
                                <h2 className='gradientText title-anime d-inline-block mb-0' ref={titleRef}>Roadmap</h2>
                                <h3 className='shadow-text small-shadow'>Roadmap</h3>
                            </div>
                        </div>
                    </div>
                    <Row className=' g-xl-5 roadmap-area-list'>
                        <Col md={6} lg={3}>
                            <div className='roadmap-card'>
                                <img src='/img/roadmap-stick.png' alt='roadmap-shape' className="roadmap-stick" />
                                <h4 className="gradientText minititle-1">Q1 - 2025</h4>
                                <p className="mb-0 text-md-light text-ref" ref={roadmaptext1}>Raffle License registration
                                    Smart contract audit<br />
                                    Launch of NFT, stable coin and altcoin raffles<br />
                                    Marketing campaigns through social media & influencers<br />
                                    Airdrop campaigns<br />
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={3}>
                            <div className='roadmap-card'>
                                <img src='/img/roadmap-stick.png' alt='roadmap-shape' className="roadmap-stick" />
                                <h4 className="gradientText minititle-2">Q2 - 2025</h4>
                                <p className="mb-0 text-md-light text-ref" ref={roadmaptext2}>
                                Pre-sale of $GAMBET token on Launchpads.<br/>
Public Sale of $GAMBET token on GAMBET platform.<br/>
Capability for Web 3 projects to run raffles on GAMBET platform.<br/>
Secure partnerships.<br/>
Airdrops and Token Launch on DEX
Staking pools activation.
                                </p>
                            </div>
                        </Col>
                        <Col md={6} lg={3}>
                            <div className='roadmap-card'>
                                <img src='/img/roadmap-stick.png' alt='roadmap-shape' className="roadmap-stick" />
                                <h4 className="gradientText minititle-3">Q3 - 2025</h4>
                                <p className="mb-0 text-md-light text-ref" ref={roadmaptext3}>Capability for new project launch token raffles<br/>
Exclusive privileges to Tier holders to premier raffles<br/>
Multiple CEX Listing</p>
                            </div>
                        </Col>
                        <Col md={6} lg={3}>
                            <div className='roadmap-card'>
                                <img src='/img/roadmap-stick.png' alt='roadmap-shape' className="roadmap-stick" />
                                <h4 className="gradientText minititle-4">Q4 - 2025</h4>
                                <p className="mb-0 text-md-light text-ref" ref={roadmaptext4}>Games of Chance launch<br/>
Token burn event to remain deflationary<br/>
Launch of mega raffle</p>
                            </div>
                        </Col>
                    </Row>
                    <img className='roadmap-road' src='/img/roadmap-road.png' alt='roadmap road' />
                </div>
            </div>
        </>
    );
};


export default RoadmapSec;
